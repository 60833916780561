<template>
  <swarm-widget :title="'Message Text'">
    <template slot="body">
      <form @submit.prevent="onSubmit">
        <span class="label">Select Device:</span>
        <select v-model="selectedDevice">
          <option v-for="device in devices" v-bind:value="device">
            {{ device.deviceName }} ( {{device.deviceId}} )
          </option>
        </select> <span class="messageCount"> Message length:{{this.messageText.length}}</span>
        <input type="text" placeholder="Message" class="input" v-model="messageText">
        <button type="submit" class="btn-block-primary">
          <button :class="{'disabled' : !match}" class='btn-block-primary'>Send Message</button>
        </button>
      </form>
    </template>
  </swarm-widget>
</template>

<script>
import swarmWidget from '@/components/general/widget'
export default {
  name: 'message-send-widget',
  data() {
    return {
      messageText: '',
      selectedDevice: null
    }
  },
  components: {
    swarmWidget
  },
  mounted() {
    this.$store.dispatch('fetchDevices');
  },
  methods: {
    onSubmit() {
      const result = {
        data: btoa(this.messageText),
        deviceId: this.selectedDevice.deviceId,
        deviceType: this.selectedDevice.deviceType,
        userApplicationId:0
      }
      this.$store.dispatch('sendMessage',JSON.stringify(result));
      this.messageText = '';
      this.$router.push({name: 'user messages'});
    }
  },
  computed: {
    match() {
      if (this.messageText.length < 192) {
        return true;
      } else {
        return false;
      }
    },
    devices() {
      return this.$store.getters.userDevices
    }
  }
}
</script>

<style lang="scss" scoped>

 .label { float:left }
 .messageCount { float:right }

</style>
